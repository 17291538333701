<template>
  <b-overlay
    :show="filteredNodes === undefined"
    class="kit-list"
    z-index="0" spinner-variant="kit"
  >
    <div class="kit-list-wrapper">
      <header class="kit-list-header">
        <page-view
          v-if="intro"
          :page="intro" no-close slug="intro"
          class="small"
        />

        <search-input v-model="search" class="input-search" />
      </header>

      <div class="kit-list-container nodes-grid">
        <node-view
          v-for="node in filteredNodes" :key="'kit-' + node.id"
          :node="node"
          mode="card"
          @click.native.capture="openNode(node.id)"
        />
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex'

import { NodeView } from '@/components/nodes'
import { PageView } from '@/components/layouts'
import { SearchInput } from '@/components/formItems'
import { searchInNode } from '@/store/utils'


export default {
  name: 'KitList',

  components: {
    NodeView,
    PageView,
    SearchInput
  },

  props: {
  },

  data () {
    return {
      search: '',
      intro: null
    }
  },

  computed: {
    ...mapGetters(['sheets']),

    filteredNodes () {
      if (!this.sheets) return
      const search = this.search.toLowerCase()
      return this.sheets.filter(node => searchInNode(search, node))
    }
  },

  methods: {
    openNode (id) {
      this.$store.dispatch('OPEN_KIT_NODE', id)
    }
  },

  async created () {
    this.$store.dispatch('INIT_KIT')
    this.$store.dispatch('QUERY_PAGE', 'kit').then(page => {
      this.intro = page
    })
  }
}
</script>

<style lang="scss" scoped>
.kit-list {
  overflow-y: auto;
  height: 100%;

  &-wrapper {
    padding: 1.15rem;

    @include media-breakpoint-up($size-bp) {
      padding: 2.15rem;
    }
  }


  &-header {
    margin-bottom: 1.15rem;

    @include media-breakpoint-up($size-bp) {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.15rem;
    }

    .input-search {
      min-width: 30%;
      margin-bottom: 2.15rem;
      margin-top: 1.15rem;

      @include media-breakpoint-up($size-bp) {
        margin: 0;
        margin-left: 1.15rem;
      }
    }
  }

  .node-view {
    cursor: pointer;
  }
}
</style>
