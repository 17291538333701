<template>
  <div class="kit-view bg-kit">
    <node-view
      v-if="sheet"
      :node="sheet"
      mode="view"
      show-origin
      @close-node="closeNode()"
      @open-node="openLibrary"
    />

    <b-overlay
      :show="!sheet"
      spinner-variant="light"
      no-wrap
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { NodeView } from '@/components/nodes'


export default {
  name: 'KitView',

  components: {
    NodeView
  },

  props: {
    id: { type: [Number, String], default: undefined }
  },

  computed: {
    ...mapGetters(['sheet'])
  },

  methods: {
    closeNode () {
      this.$store.dispatch('CLOSE_KIT_NODE')
    },

    openLibrary (ids) {
      this.$store.dispatch('OPEN_NODE', ids)
    }
  },

  created () {
    this.$store.dispatch('INIT_KIT_VIEW', parseInt(this.id))
  }
}
</script>

<style lang="scss">
.kit-view {
  height: 100%;
  width: 100%;

  .node-view {
    min-height: 100%;
    &-header-wrapper,
    &-body-wrapper,
    &-footer-wrapper,
    &-gallery {
      @include media-breakpoint-up($layout-bp) {
        max-width: 50%;
        margin: auto;
      }
    }

    .btn-close {
      @include media-breakpoint-up($layout-bp) {
        position: absolute;
        right: $node-view-spacer-x;
      }
    }
  }
}
</style>
